html, body {
    max-width: 100% !important;
    overflow-x: hidden !important;
}
body{
    font-family: 'Fredoka', sans-serif;
    font-family: 'Inter', sans-serif;
    font-family: 'Montserrat', sans-serif;
    font-family: 'Playfair Display', serif;
    font-family: 'Vazirmatn', sans-serif;
    margin:0;
}
.contact-link{
    width: 30%;
}
.hide{
    display: none;
}
.navbar-container{
    list-style: none;
    display: flex;
    padding: 2% 3%;
    background-color: #2d3891;
}
#navbar-un{
    position: fixed; 
    width: 96%;
    justify-content: center;
}

li {
    margin-left: 4.5%;
}
a {
    text-decoration: none;
    color: white;
}
svg.icon-contact {
    width: 5%;
    display: block;
    margin-top: 1%;
}
.contact-text {
    margin-top: 3.5%;
    margin-left: 3%;
}
.tcontainer {
    width: 100%;
    overflow: hidden;
}

.ticker-wrap {
    width: 100%;
    padding-left: 100%;
    background-color: #2d3891;
    color: white;
    padding: 1.5%;
}
#email-2 {
    border: none;
    padding: 1% 4%;
    margin-left: 40%;
    margin-top: -1%;
    margin-bottom: 4%;
}
@keyframes ticker {
    100% {
        transform: translate3d(-100%, 0, 0);
    }
}

.ticker-move {
    display: inline-block;
    white-space: nowrap;
    padding-right: 100%;
    animation-timing-function: linear;
    animation: ticker 40s infinite;
}

.ticker-move:hover {
    animation-play-state: paused;
}

.ticker-item {
    display: inline-block;
    padding: 0 40px;
    font-size: 20px;
}
.row {
    display: flex;
}
.container {
    padding: 2%;
    margin-bottom: 4%;
    width: 37%;
    margin-left: 0%;
}
.extra-padding {
    padding-right: 7%;
}

.extra-padding p{
    text-align: center;
}

.main-text{
    font-family: 'Fredoka', sans-serif;
    font-family: 'Inter', sans-serif;
    font-family: 'Montserrat', sans-serif;
    font-family: 'Playfair Display', serif;
    font-family: 'Vazirmatn', sans-serif;
}
img.the_logo {
    width: 53%;
    margin-left: 21%;
}
.logo {
    width: 20%;
}
.extra_space {
    margin-top: 3%;
    color: white;
}
.links {
    width: 100%;
}
.footer {
    background-color: #2d3891;
    padding-top: 2.5%;
}
#extra_space li {
    margin-left: 8.5%;
    width: 15%;
}
.hide{
    display: block;
}
.emoji {
    font-size: 97px;
    text-align: center;
}
.container h2 {
    text-align: center;
}
.team-members {
    width: 100%;
}
.box {
    width: 50%;
}
.input-field {
    width: 85%;
    padding: 3%;
    margin-bottom: 2%;
    border-color: #2d3891;
    border-style: solid;
}
button#submit-button {
    padding: 2%;
    border: none;
    background-color: #2d3891;
    color: white;
    transition-duration: 1s;
}
img.hero-img {
    width: 60%;
    border-radius: 20px;
}
.box-align {
    width: 50%;
    padding: 1%;
}
html {
    scroll-behavior: smooth;
}
.left-align {
    text-align: left;
}
.right-align {
    text-align: right;
}
.right {
   margin-left: '5%'; 
}
.logo2 {
    width: 27%;
    margin-top: -5%;
    margin-bottom: 7%;
}
.team-img {
    text-align: center;
    width: 50%;
}
.row-2 {
    display: flex;
    flex-wrap: wrap;
    padding-left: 0;
}
.contributor {
    list-style: none;
    flex: 0 0 31.333333%;
    margin-right: 2%;
}
.navbar-2 {
    display: none;
}

.invisible{
    display: none;
}

.socials {
    background-color: #2d3891;
    /* margin-top: -2%;
    padding: 2%; */
    margin-top: -3%;
    padding: 2%;
    width: 74%;
    margin-left: 11%;
}
.socials-icons {
    margin-left: 4%;
}
.submit-button:hover{
    background-color: #1b2852;
}
.team-members-2{
    width: 78%;
}
/* Mobile Phones */
@media(max-width: 750px){
    .navbar-2 {
        display: block;
        padding: 4%;
        position: fixed;
        width: 100%;
        background-color: black;
    }
    iframe{
        width: 330px;
        height: 200px;
    }
    #lrn-more{
        padding: 7% 5%;
        margin-bottom: 75%;
    }
    svg.icon-contact {
        width: 9%;
        margin-top: 8%;
    }
    .contact-text {
        margin-top: -8.5%;
        margin-left: 12%;
    }
    #extra_space li {
        width: 100%;
    }
    .hide{
        display: none;
    }
    .socials {
        margin-top: -3%;
        margin-bottom: -18%;
    }
    .about-section h1{
        margin-top: 22%;
    }
    .hero-section h1 {
        font-size: 400%;
        line-height: 1;
        margin-bottom: 38%;
    }
    svg.svg-inline--fa.fa-bars {
        margin-left: 87%;
    }
    #menu{
        position: absolute;
        background-color: white;
        top: 20%;
        left: 15%;
        width: 66%;
        padding: 11% 2%;
        position: fixed;
        border-radius: 29px;
        list-style: none;
        line-height: 4;
        font-size: 140%;
        box-shadow: 4px 4px 8px 0px rgba(34, 60, 80, 0.2);
    }
    .row {
        display: block;
    }
    #email-2 {
        padding: 3% 4%;
        margin-left: 24%;
        margin-top: 10%;
    }
    .ticker-item {
        margin-top: 3%;
    }
    img.the_logo {
        width: 158%;
        margin-left: 164%;
        margin-top: 51%;
    }
    .container {
        width: 94%;
    }
    .extra-padding {
        padding-right: 0%;
    }
    .box {
        width: 100%;
    }
    .navbar {
        display: none;
    }
    .navbar-container {
        display: block;
        font-size: 88%;
        line-height: 2.5;
    }
    .hero-section h1 {
        font-size: 300%;
        margin-top: -19%;
    }
    .logo2 {
        width: 100%;
        margin-top: 26%;
        margin-bottom: 14%;
    }
    .lrn-more {
        margin-bottom: 21%;
        padding: 7% 5%;
    }
    .container h2 {
        width: 80%;
        margin-top: 0%;
        margin-left: 10%;
    }
    .team-img {
        width: 100%;
        margin-left: -2%;
    }
    .shift-up {
        margin-top: -21%;
    }
}

/* Ipad */
@media (min-width: 800px) and (max-width: 1100px) {
    .extra-padding p {
        text-align: center;
        font-size: 87%;
    }
    img.the_logo {
        width: 228%;
        margin-left: 173%;
        margin-top: 27%;
    }
    .container h2 {
        font-size: 134%;
        margin-top: -32%;
    }
    li {
        margin-left: 9%;
        text-align: center;
        font-size: 95%;
    }  
    #extra_space li {
        margin-left: 4.5%;
    }
    img.hero-img {
        width: 92%;
        margin-top: 15%;
    }  
}
@media screen and (min-device-width: 750px) and (max-device-width: 1000px){
    iframe{
        width: 370px;
        height: 300px;
    }
    .contact-link{
        width: 58%;
        margin-left: -2.5%;
        width: 36%;
    }
    img.the_logo {
        width: 101%;
        margin-left: 262%;
        margin-top: 45%;
        margin-bottom: 15%;
    }
}